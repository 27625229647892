/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
//@import '~animate.css/animate.min.css';

@import 'material-icons/iconfont/filled.css';

body {
  font-family: 'Lato';
}

ion-searchbar{
    //margin-top: 15px;
    // margin-left: 5px;
    // margin-right: 5px;
    // padding-right: 5px;
    // padding-left: 5px;
    .searchbar-input-container{
        .searchbar-input{
            border-radius: 13px !important;
            background-color: white;
            box-shadow: none !important;
            //padding-right: 5px;
            margin-right: 5px;
            padding-right: 10px;
        }
    }
}

.cart-modal {
    --height: 50%;
    --border-radius: 10px;
    padding: 25px;
  }


  .card-alert {
    width: 120px;
    height: 120px;
    margin-left: 80px;
    margin-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.google_map_container .map-container {
    width: 100% !important;
    height: 400px !important;
    border-radius: 4px !important;
  }
  
  .custom-map-control-button {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px #0000004d;
    cursor: pointer;
    font: 400 18px Roboto,Arial,sans-serif;
    height: 40px;
    margin: 10px;
    overflow: hidden;
    padding: 0 0.5em;
  }

  .otpInput {
    background-color: white;
  }

  .page-title {
    font-family: 'Lato'; 
    font-size: 20px; 
    font-weight: 700;
  }